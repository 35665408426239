import React from 'react';
import AspectRatio from 'react-aspect-ratio';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styled from 'styled-components';

const TeammatesItem = styled.div`
    width: calc(16.666% - 25px);
    margin-right: 30px;
    margin-top: 28px;
    border-radius: 4px;
    img {
        border-radius: 4px;
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
    &:nth-child(6n) {
        margin-right: 0;
    }
    &:nth-child(2n) {
        @media screen and (max-width: 767px) {
            margin-right: 0;
        }
    }
    @media screen and (max-width: 767px) {
        width: calc(50% - 16px);
    }
`;

const TeammatesName = styled.div`
    margin-top: 16px;
    font-size: 16px;
    line-height: 24px;
    color: white;
    letter-spacing: -0.2px;
    text-align: left;
    font-family: 'Renogare-Regular';
`;
const TeammatesPosition = styled.div`
    font-family: 'Neuzeit Grotesk';
    font-size: 15px;
    line-height: 20px;
    color: rgba(255, 255, 255, 0.7);
    text-align: left;
`;

const TeammateItem = ({ teammate }) => {
    const { name, image, job } = teammate;
    return (
        <TeammatesItem>
            <AspectRatio>
                <LazyLoadImage
                    alt={name}
                    effect="blur"
                    src={image} // use normal <img> attributes as props
                    width={'100%'}
                />
            </AspectRatio>
            <TeammatesName>{name}</TeammatesName>
            <TeammatesPosition>{job}</TeammatesPosition>
        </TeammatesItem>
    );
};

export default TeammateItem;
