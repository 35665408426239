import React from 'react';

import 'react-aspect-ratio/aspect-ratio.css';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Layout from '../layout/main';
import CTAComponent from '../components/cta';
import SEOComponent from '../components/seo';
import { Wave3 } from '../components/wave';
import Title from '../components/typography/Title';
import SubTitle from '../components/typography/Subtitle';
import Desc from '../components/typography/Desc';
import TeammateItem from '../components/teammatesItem/TeammatesItem';

const Background = styled.div`
    background-color: transparent;
    margin-bottom: 277px;
    @media screen and (max-width: 767px) {
        margin-bottom: 153px;
    }
`;
const Heading = styled.div`
    margin-left: 176px;
    margin-right: 176px;
    padding-top: 149px;
    @media screen and (max-width: 1024px) {
        margin-left: 64px;
        margin-right: 64px;
    }
    @media screen and (max-width: 767px) {
        margin-left: 24px;
        margin-right: 24px;
        padding-top: 108px;
    }
`;

const TeammatesContainer = styled.div`
    margin-top: -75px;
    margin-left: 64px;
    margin-right: 64px;
    @media screen and (max-width: 767px) {
        margin-left: 24px;
        margin-right: 24px;
    }
`;
const TeammatesHeading = styled.div`
    font-family: 'Renogare-Regular';
    font-size: 36px;
    line-height: 40px;
    letter-spacing: -1.5px;
    text-align: center;
    color: white;
    @media screen and (max-width: 767px) {
        font-size: 24px;
        line-height: 28px;
        letter-spacing: -0.92px;
    }
`;
const TeammatesContent = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    justify-content: flex-start;
    @media screen and (max-width: 767px) {
        margin-top: 0px;
    }
`;

// const WaveSection = styled.div`
//     position: absolute;
//     width: 100%;
//     margin-top: -50px;
// `;

export default function AboutUsPage({ data }) {
    const { hero, teammates, book_now, seo_content } = data.butterPage;
    return (
        <Layout menu="ABOUT US">
            <SEOComponent
                title="About Us"
                image={seo_content.sharing_image}
                description={seo_content.seo_description}
            />
            <Background>
                <Heading>
                    <Title>{hero[0].title}</Title>
                    <SubTitle>{hero[0].subtitle}</SubTitle>
                    <Desc>{hero[0].description}</Desc>
                </Heading>
                <Wave3 />
                <TeammatesContainer>
                    <TeammatesHeading>Team</TeammatesHeading>
                    <TeammatesContent>
                        {teammates.map(teammate => {
                            const { id } = teammate.meta;
                            return (
                                <TeammateItem teammate={teammate} key={id} />
                            );
                        })}
                    </TeammatesContent>
                </TeammatesContainer>
            </Background>
            <CTAComponent {...book_now[0]} />
        </Layout>
    );
}

export const smartOTPPageQuery = graphql`
    query {
        butterPage(slug: { eq: "about" }) {
            seo_content {
                seo_description
                sharing_image
            }
            hero {
                title
                description
                subtitle
            }

            teammates {
                name
                image
                job
                meta {
                    id
                }
            }

            book_now {
                button_title
                title
            }
        }
    }
`;
