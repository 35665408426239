import React from 'react';
import * as PropTypes from 'prop-types';

import styled from 'styled-components';

const DescElement = styled.div`
    margin-top: 6px;
    color: white;
    font-family: 'Neuzeit Grotesk';
    font-size: 23px;
    line-height: 32px;
    @media screen and (max-width: 767px) {
    }
`;

const typeMap = {
    desc: DescElement,
};

const Desc = ({ children, descType }) => {
    const Component = typeMap[descType] || DescElement;
    return <Component>{children}</Component>;
};

Desc.defaultProps = {
    desc: null,
};

Desc.propTypes = {
    children: PropTypes.node.isRequired,
    descType: PropTypes.string,
};

export default Desc;
